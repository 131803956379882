import isNumber from "lodash/isNumber";

import makeFullName from "utils/makeFullName";
import {formatPayment} from "utils/currency";

export const formatUserSuggestions = users =>
  users.map(({firstName, lastName, rentPassportInstantStatus, ...rest}) => {
    const {
      weeklyRentAffordability: wklyRentAffo,
      monthlyRentAffordability: mthlyRentAffo,
      ...props
    } = rest;
    const isWklyRentAffoNumber = isNumber(wklyRentAffo);
    const isMthlyRentAffoNumber = isNumber(mthlyRentAffo);

    return {
      label: rest.userId,
      value: {
        fullName: makeFullName({firstName, lastName}),
        formattedName: firstName[0] + lastName[0],
        status: rentPassportInstantStatus,
        formatted: {
          wklyRentAffo: isWklyRentAffoNumber ? formatPayment({amount: wklyRentAffo}) : 0,
          mthlyRentAffo: isMthlyRentAffoNumber ? formatPayment({amount: mthlyRentAffo}) : 0,
        },
        weeklyRentAffordability: isWklyRentAffoNumber ? wklyRentAffo : 0,
        monthlyRentAffordability: isMthlyRentAffoNumber ? mthlyRentAffo : 0,
        ...props,
      },
    };
  });
