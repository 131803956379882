import {http} from "http/index";
import qs from "utils/query";

const PREFIX = "/agent/user";

export const checkEmailAvailability = email =>
  http.post(`${PREFIX}/check-email-availability`, {email});

export const resendVerificationEmail = ({email}) =>
  http.post(`${PREFIX}/resend-activation`, {email});

/**
 * Requests information about the renter with ones screening type
 */
export const getRenterInfoById = ({branchId, guarantor, renterId}) =>
  http.get(
    `${PREFIX}/${renterId}${qs.stringify({branchId, guarantor: !!guarantor}, {skipNulls: true})}`,
  );
