import {formatDate, parseAndGetTime} from "utils/date";
import {branchesNamesToString} from "utils/branches";
import fullNameToString from "utils/makeFullName";
import {userStatus} from "constants/users";

export const formatUsersList = data => ({
  total: data.length,
  list: data.map(
    ({firstName, middleName, lastName, branches, isActive, createdAt, type, email, ...el}) => {
      const fullName = fullNameToString({firstName, middleName, lastName});
      const formattedBranches = branchesNamesToString(branches);

      return {
        id: el.id,
        formattedName: firstName[0] + lastName[0],
        fullName,
        status: isActive ? userStatus.ACTIVE : userStatus.INACTIVE,
        email,
        userType: type,
        branches: formattedBranches,
        passports: el.passports,
        deposits: el.deposits,
        dateAdded: formatDate({date: createdAt}),
        sort: {
          fullName: fullName.toLowerCase(),
          email: email.toLowerCase(),
          branches: formattedBranches.toLowerCase(),
          dateAdded: parseAndGetTime(createdAt),
          userType: type.toLowerCase(),
        },
      };
    },
  ),
});

export const formatInvitedUsersList = data => ({
  list: data.map(({id, email, invitedAt}) => ({
    id,
    email,
    formattedName: "...",
    invitedAt: formatDate({date: invitedAt}),
    sort: {
      email: email.toLowerCase(),
      invitedAt: parseAndGetTime(invitedAt),
    },
  })),
  total: data.length,
});

export const formatUserDetails = ({
  branches,
  type,
  rentPassports = {},
  deposits = {},
  firstName,
  lastName,
  middleName,
  ...rest
}) => ({
  role: type,
  userBranches: branches && branches.map(b => b.id),
  rentPassports: {
    total: rentPassports.completed + rentPassports.inProgress || "-",
    completed: rentPassports.completed || "",
    inProgress: rentPassports.inProgress || "",
  },
  deposits: {
    total: deposits.active + deposits.inProgress || "-",
    active: deposits.active || "",
    inProgress: deposits.inProgress || "",
  },
  firstName,
  lastName,
  middleName,
  fullName: fullNameToString({firstName, middleName, lastName}),
  ...rest,
});
