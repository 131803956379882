import {compareDesc} from "date-fns";

import makeFullName, {makeFullNameShortcut} from "utils/makeFullName";
import {formatDate, parseAndGetTime} from "utils/date";
import {RENT_FREQUENCY} from "constants/deposits";
import {statusesForRentPassportScreeningType, screeningStatuses} from "constants/screeningStatuses";
import {branchesNamesToString} from "utils/branches";

const getUserData = user => {
  const formattedUser = user ?? {};
  const {fullName, ...userNames} = makeFullName({...formattedUser, withOriginalValues: true});
  const email = formattedUser.email || "";

  return {
    fullName,
    formattedName: makeFullNameShortcut(user),
    email,
    userNames,
  };
};

const formatInSingleBranchMode = ({total, list}) => ({
  total,
  list: list
    .map(({user, isFavourite, ...el}) => {
      const isValidAffordability = !Number.isNaN(+el.monthlyRentAffordability);
      const {fullName, formattedName, email, userNames} = getUserData(user);
      const isPreQualified =
        el.referencingType === statusesForRentPassportScreeningType.PRE_QUALIFICATION;

      const isOverallCompletedStatus = [
        screeningStatuses.ACCEPT,
        screeningStatuses.HIGH_RISK,
        screeningStatuses.CONSIDER,
      ].includes(el.srOverall);

      return {
        fullName,
        formattedName,
        email,
        status: isPreQualified ? "" : el.srOverall,
        overallReady: el.isCompleted,
        isOverallCompletedStatus,
        isPreQualified,
        referencingType: el.referencingType,
        fullReferencingState: el.referencingType,
        affordability: el.monthlyRentAffordability ?? "...",
        tenancyStatus: el.tenancyStatus,
        connectionId: el.connectionId,
        date: formatDate({date: el.connectedAt}),
        id: user.id,
        favorites: isFavourite,
        paymentFrequency: RENT_FREQUENCY.MONTHLY,
        search: {
          fullName: userNames,
        },
        sort: {
          fullName: fullName.toLowerCase(),
          email: email.toLowerCase(),
          date: parseAndGetTime(el.connectedAt),
          affordability: isValidAffordability ? +el.monthlyRentAffordability : -1,
        },
      };
    })
    .sort((a, b) => compareDesc(a.sort.date, b.sort.date)),
});

const formatInAllBranchesMode = ({total, list}) => ({
  total,
  list: list.map(({user, branches, isFavourite, ...el}) => {
    const {fullName, formattedName, email, userNames} = getUserData(user);
    const isValidAffordability = !Number.isNaN(+el.monthlyRentAffordability);
    const formattedBranches = branchesNamesToString(branches.map(elem => ({name: elem})));

    return {
      fullName,
      formattedName,
      email,
      affordability: el.monthlyRentAffordability ?? "...",
      id: user.id,
      favorites: isFavourite,
      branches: formattedBranches,
      search: {
        fullName: userNames,
      },
      sort: {
        fullName: fullName.toLowerCase(),
        email: email.toLowerCase(),
        affordability: isValidAffordability ? +el.monthlyRentAffordability : -1,
        branches: formattedBranches.toLowerCase(),
      },
    };
  }),
});

export const formatConnectedUsers = branchId => props =>
  branchId ? formatInSingleBranchMode(props) : formatInAllBranchesMode(props);
