import {depositFormulae, propertyManager} from "constants/deposits";
import {formatPayment} from "utils/currency";

export const detectPropManager = ({data, translations}) => {
  let propManager;

  if (data.propertyManager === propertyManager.AGENT) {
    propManager = translations.agent;
  } else {
    propManager = translations.landlord;
  }

  return {
    ...data,
    propertyManager: propManager,
    landlordName: data.landlordName || "-",
    landlordEmail: data.landlordEmail || "-",
    landlordPhone: data.landlordPhone || "-",
  };
};

export const prepareValuesForDepositAgreementOptions = ({data}) => {
  const {rentAmount, tenantUsers} = data;

  const dfiCoverAmount = depositFormulae.dfiCoverAmount(rentAmount);

  let depositAmount = 0;

  const formattedTenants = tenantUsers.map(el => {
    if (el.depositCost) {
      depositAmount += el.depositCost;
    } else {
      depositAmount = null;
    }

    return {
      ...el,
      depositCost: el.depositCost && formatPayment({amount: el.depositCost}),
    };
  });

  return {
    ...data,
    rentAmount: formatPayment({amount: data.rentAmount}),
    agreementOptions: {
      depositAmount: depositAmount ? formatPayment({amount: depositAmount}) : null,
      dfiCoverAmount: formatPayment({amount: dfiCoverAmount}),
    },
    tenantUsers: formattedTenants,
  };
};
