const initialState = {
  data: {
    list: [],
    total: 0,
  },
  isLoading: false,
  error: "",
};

export const getDeposits = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const clearList = () => ({...initialState});

export default initialState;
