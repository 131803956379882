import {createSlice} from "@reduxjs/toolkit";

import {getList} from "http/branches";
import {formatBranch} from "http/branches/formatters";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {debouncedAction} from "redux/helpers/debouncedAction";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getBranchesRequest: startLoading,
    getBranchesSuccess: handlers.getBranchListSuccess,
    successUpdateBranches: handlers.successUpdateBranches,
    getBranchesFailure: loadingFailed,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "branchesList",
});

export const getBranches = debouncedAction(agencyId => async dispatch => {
  try {
    dispatch(actions.getBranchesRequest());

    const data = await getList(agencyId);

    dispatch(actions.getBranchesSuccess(data));
  } catch (error) {
    dispatch(actions.getBranchesFailure(extractError(error)));
  }
});

export const successUpdateBranches = ({branchData, branchId}) => async (dispatch, getState) => {
  const {data} = getState().branchesList;
  const branchDataList = [...data];
  const formattedBranch = formatBranch(branchData);
  // When we add new branch, there are only one agent (creator) and no passport/deposits
  const additionalBranchInfo = {
    users: 1,
    passports: 0,
    deposits: 0,
  };

  if (branchId) {
    const updatedElementIndex = branchDataList.findIndex(el => el.id === branchId);

    if (updatedElementIndex > -1) {
      const {users, passports, deposits} = branchDataList[updatedElementIndex];

      additionalBranchInfo.users = users;
      additionalBranchInfo.passports = passports;
      additionalBranchInfo.deposits = deposits;

      branchDataList.splice(updatedElementIndex, 1);
    }
  }
  branchDataList.unshift({...formattedBranch, ...additionalBranchInfo});

  dispatch(actions.successUpdateBranches(branchDataList));
};

export default reducer;
