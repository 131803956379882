import {format, getTime} from "date-fns";

import {DATE_FORMAT} from "utils/date";
import {addressToString} from "utils/addresses";
import {formatPayment} from "utils/currency";
import makeFullName, {makeFullNameShortcut} from "utils/makeFullName";
import {DEPOSIT_TYPE, statusMapping} from "constants/deposits";

export const formatList = list => ({
  total: list.length,
  list: list.map(({id, renters, depositType, ...el}) => {
    const address = addressToString(el.address);
    const startDate = new Date(el.startDate);
    const endDate = new Date(el.endDate);
    const isDepositAmount = !Number.isNaN(+el.depositAmount);

    return {
      id,
      address,
      landlord: el.landlordName,
      renters,
      depositType,
      deposit: el.depositAmount,
      depositStatus: statusMapping[el.depositStatus],
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(endDate, DATE_FORMAT),
      sort: {
        address: address.toLowerCase(),
        landlord: el.landlordName,
        startDate: getTime(startDate),
        endDate: getTime(endDate),
        deposit: isDepositAmount ? +el.depositAmount : -1,
        renters,
        depositType: depositType.toLowerCase(),
      },
    };
  }),
});

export const formatDetails = ({
  depositStatus,
  myDepositReferenceId,
  paymentFrequency,
  depositType,
  branchId,
  landlordName,
  landlordEmail,
  landlordPhone,
  agentFirstName,
  agentMiddleName,
  agentLastName,
  propertyManager,
  ...data
}) => {
  const address = addressToString(data.address);
  const startDate = new Date(data.startDate);
  const endDate = new Date(data.endDate);

  return {
    depositStatus: statusMapping[depositStatus],
    myDepositReferenceId,
    landlordName,
    landlordEmail,
    landlordPhone,
    agreementOwner: makeFullName({
      firstName: agentFirstName,
      middleName: agentMiddleName,
      lastName: agentLastName,
    }),
    address,
    startDate: format(startDate, DATE_FORMAT),
    endDate: format(endDate, DATE_FORMAT),
    paymentFrequency,
    rentAmount: data.rentAmount,
    depositFreeInsurance: depositType === DEPOSIT_TYPE.DEPOSIT_FREE_INSURANCE,
    depositType,
    propertyManager,
    branchId,
    tenantUsers: data.tenantUsers.map(
      ({tenantFirstName, tenantLastName, tenantEmail, userId, ...rest}) => {
        const fullName = makeFullName({firstName: tenantFirstName, lastName: tenantLastName});
        const formattedName = makeFullNameShortcut({
          firstName: tenantFirstName,
          lastName: tenantLastName,
        });

        return {
          fullName,
          formattedName,
          tenantEmail,
          userId,
          depositStatus: statusMapping[rest.depositStatus],
          depositCost: rest.depositCost,
          affordability: formatPayment({amount: rest.tenantMonthlyRentAffordability}),
          rentShare: formatPayment({amount: rest.rentAmount}),
          paymentAmount: formatPayment({amount: rest.paymentAmount}),
        };
      },
    ),
  };
};
