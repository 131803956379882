export const overviewFormatter = ({
  agents,
  branches,
  deposits,
  rentPassports,
  tenancies,
  showOnTheMarketLogo,
}) => ({
  branches,
  deposits: {
    active: deposits.active,
    inProgress: deposits.inProgress,
    value: deposits.active + deposits.inProgress,
  },
  rentPassports: {
    complete: rentPassports.completed,
    inProgress: rentPassports.inProgress,
    value: rentPassports.completed + rentPassports.inProgress,
  },
  tenancies,
  showOnTheMarketLogo,
  users: agents,
});
