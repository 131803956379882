import {http} from "http/index";
import qs from "utils/query";

import {formatList, formatDetails} from "./formatters";

export const getList = async ({branchId}) =>
  http.get(`/agent/tenancy-agreement${qs.stringify({branchId})}`).then(formatList);

export const create = async data => http.post(`/agent/tenancy-agreement`, data);

export const getDetails = async ({id, branchId}) =>
  http.get(`/tenancy-agreement${qs.stringify({tenancyId: id, branchId})}`).then(formatDetails);
