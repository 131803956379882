const initialState = {
  data: {
    branches: null,
    deposits: {
      active: null,
      inProgress: null,
      value: null,
    },
    showOnTheMarketLogo: false,
    rentPassports: {
      complete: null,
      inProgress: null,
      value: null,
    },
    users: null,
  },
  error: "",
  isLoading: true,
  landlordInsuranceReferralLink: null,
  propertiesCount: null,
  showLandlordInsuranceBanner: false,
  verificationProvider: {},
};

export const getCompanyDataSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const requestCompanyBrandingSuccess = (state, {payload}) => ({
  ...state,
  data: {...state.data, showOnTheMarketLogo: payload.showOnTheMarketLogo},
});

export const requestCompanyBrandingFailed = (state, {payload}) => ({
  ...state,
  error: payload,
});

export const requestVerificationProviderInformationSuccess = (state, {payload}) => ({
  ...state,
  isLoading: false,
  verificationProvider: payload,
});

export const updateBranchesCount = state => ({
  ...state,
  data: {...state.data, branches: state.data.branches + 1},
});

export const getLISetUpRequestSuccess = (state, {payload}) => ({
  ...state,
  showLandlordInsuranceBanner: payload,
});

export const getLIReferralLinkRequestSuccess = (state, {payload}) => ({
  ...state,
  landlordInsuranceReferralLink: payload,
});

export const getPropertiesCountSuccess = (state, {payload}) => ({
  ...state,
  propertiesCount: payload,
});

export const clearState = () => ({...initialState});

export default initialState;
