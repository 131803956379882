import {createSlice} from "@reduxjs/toolkit";
import {format} from "date-fns";
import {FORM_ERROR} from "final-form";

import {getDetails, create} from "http/deposit";
import {getBranchInfo} from "http/branches";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import analytics from "services/analytics";
import {addressLineFormatter} from "utils/addresses";
import {propertyManager as propertyManagerEnum} from "constants/deposits";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";
import {detectPropManager, prepareValuesForDepositAgreementOptions} from "./helpers";

const CREATE_DEPOSIT_DATE_FORMAT = "yyyy-MM-dd";

const {actions, reducer} = createSlice({
  reducers: {
    createDepositRequest: startLoading,
    createDepositSuccess: handlers.createDepositSuccess,
    createDepositFailure: loadingFailed,

    getDetailsRequest: startLoading,
    getDetailsSuccess: handlers.getDetailsSuccess,
    getDetailsFailure: loadingFailed,

    clear: handlers.clear,
  },
  extraReducers: {
    [logout]: handlers.clear,
  },
  initialState,
  name: "depositDetails",
});

export const createDeposit = ({values: data, redirectToDeposit}) => async dispatch => {
  try {
    const {landlordPhone, tenants, address, propertyManager} = data;

    const formattedData = {
      ...data,
      propertyManager:
        propertyManager === false ? propertyManagerEnum.AGENT : propertyManagerEnum.LANDLORD,
      address: addressLineFormatter(address),
      startDate: format(data.startDate, CREATE_DEPOSIT_DATE_FORMAT),
      endDate: format(data.endDate, CREATE_DEPOSIT_DATE_FORMAT),
      rentAmount: +data.rentAmount,
      tenants: tenants.map(({email, rentShare}) => ({email, rentAmount: +rentShare})),
      landlordPhone:
        typeof landlordPhone === "string" && landlordPhone.length === 0 ? undefined : landlordPhone,
    };

    const {tenancyId} = await create(formattedData);

    analytics.logEvent("agentDfiCreated");
    redirectToDeposit(tenancyId);

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.createDepositFailure(error));
    analytics.logEvent("agentDfiCreatedError", {error});

    return {[FORM_ERROR]: error};
  }
};

export const getDepositById = ({id, activeBranchId}) => async (dispatch, getState) => {
  const {propManagement} = getState().locales.translations.modules.deposits;

  try {
    dispatch(actions.getDetailsRequest());

    const {branchId, ...data} = await getDetails({id, branchId: activeBranchId || undefined});
    const branch = await getBranchInfo({branchId});

    const withPropManagement = detectPropManager({data, translations: propManagement});
    const withPreparedAgreementOptions = prepareValuesForDepositAgreementOptions({
      data: withPropManagement,
    });

    dispatch(actions.getDetailsSuccess({data: withPreparedAgreementOptions, branch, branchId}));
  } catch (error) {
    dispatch(actions.getDetailsFailure(extractError(error)));
  }
};

export const {clear} = actions;

export default reducer;
