import {createSlice} from "@reduxjs/toolkit";

import {getList} from "http/deposit";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";
import {formatDepositType} from "./helpers";

const {actions, reducer} = createSlice({
  reducers: {
    getDepositsRequest: startLoading,
    getDepositsSuccess: handlers.getDeposits,
    getDepositsFailure: loadingFailed,

    clearList: handlers.clearList,
  },
  extraReducers: {
    [logout]: handlers.clearList,
  },
  initialState,
  name: "depositList",
});

export const getDeposits = ({activeBranchId}) => async (dispatch, getState) => {
  const {depositType} = getState().locales.translations;
  const {isAllBranchesMode} = getState().branchSelector;

  try {
    dispatch(actions.getDepositsRequest());

    const data = await getList({branchId: isAllBranchesMode ? undefined : activeBranchId});

    dispatch(actions.getDepositsSuccess(formatDepositType({...data, labels: depositType})));
  } catch (error) {
    dispatch(actions.getDepositsFailure(extractError(error)));
  }
};

export const {clearList} = actions;

export default reducer;
