export const RENT_FREQUENCY = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
};

export const CREATE_DEPOSIT_NOTIFICATION_TYPES = {
  WARNING: "warning",
  ERROR: "error",
};

export const DEPOSIT_TYPE = {
  CASH_DEPOSIT: "CASH_DEPOSIT",
  DEPOSIT_FREE_INSURANCE: "DEPOSIT_FREE_INSURANCE",
};

export const statuses = {
  REFUNDED: "REFUNDED",
  PAID: "PAID",
};

export const statusMapping = {
  REFUNDED: statuses.REFUNDED,
  ACTIVE: statuses.PAID,
};

export const iconConfig = {
  [statuses.REFUNDED]: {
    iconName: "dots-horizontal",
    type: "default",
    textKey: "refunded",
  },
  [statuses.PAID]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "paid",
  },
};

export const propertyManager = {
  AGENT: "AGENT",
  LANDLORD: "LANDLORD",
};

export const CREATE_DEPOSIT_NOTIFICATION_MAP = {
  rentShareMoreAffordability: {
    key: "rentShareMoreAffordability",
    type: CREATE_DEPOSIT_NOTIFICATION_TYPES.WARNING,
  },
  totalRentShareNotEqualRentAmount: {
    key: "totalRentShareNotEqualRentAmount",
    type: CREATE_DEPOSIT_NOTIFICATION_TYPES.ERROR,
  },
  rentPassportIncomplete: {
    key: "rentPassportIncomplete",
    type: CREATE_DEPOSIT_NOTIFICATION_TYPES.WARNING,
  },
  rentersDoesNotQualifyForDFI: {
    key: "rentersDoesNotQualifyForDFI",
    type: CREATE_DEPOSIT_NOTIFICATION_TYPES.ERROR,
  },
};

export const DEPOSIT_NOTIFICATION_LIST = Object.keys(CREATE_DEPOSIT_NOTIFICATION_MAP);

const calculateWeeklyRent = ({paymentFrequency, rentAmount}) => {
  let weeklyRent = 0;

  if (paymentFrequency === RENT_FREQUENCY.WEEKLY) {
    weeklyRent = rentAmount;
  } else {
    weeklyRent = (12 / 52) * rentAmount;
  }

  return weeklyRent;
};

export const depositFormulae = {
  depositAmount: value => Math.floor(5 * value),
  cashDepositFreeCover: value => Math.floor(8 * value),
  dfiCoverAmount: value => Math.floor(8 * value),
  calculateWeeklyRent,
};
