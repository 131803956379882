const initialState = {
  branch: {
    name: "",
    phone: "",
    address: "",
  },
  data: {
    tenantUsers: [],
    propertyManager: "",
    landlordName: "",
    landlordEmail: "",
    landlordPhone: "",
    agreementOwner: "",
    address: "",
    startDate: "",
    endDate: "",
    depositStatus: "",
    paymentFrequency: "",
    rentAmount: "",
    agreementOptions: {
      depositAmount: "",
      cashDepositFreeCover: "",
      dfiCoverAmount: "",
    },
  },
  error: "",
  isLoading: false,
  lastSuccessfulBranchId: "",
};

export const createDepositSuccess = state => ({
  ...state,
  isLoading: false,
});

export const getDetailsSuccess = (state, {payload}) => ({
  ...state,
  branch: payload.branch,
  data: payload.data,
  isLoading: false,
  lastSuccessfulBranchId: payload.branchId,
});

export const clear = state => ({
  ...initialState,
  lastSuccessfulBranchId: state.lastSuccessfulBranchId,
});

export default initialState;
