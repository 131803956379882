const initialState = {
  data: {
    name: "",
    address: "",
    phone: "",
  },
  overview: {
    users: null,
    rentPassports: {
      value: null,
      complete: null,
      inProgress: null,
    },
    deposits: {
      value: null,
      active: null,
      inProgress: null,
    },
  },
  isLoading: false,
  error: "",
};

export const getBranchOverviewSuccess = (state, {payload}) => ({
  ...state,
  data: payload.data,
  overview: payload.overview,
  isLoading: false,
});

export const updateBranchSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
});

export const clearState = () => ({...initialState});

export default initialState;
