const initialState = {
  data: {
    id: null,
    firstName: "",
    lastName: "",
    middleName: "",
    fullName: "",
    email: "",
    role: "",
    branches: [],
    rentPassports: {
      complete: null,
      inProgress: null,
    },
    deposits: {
      active: null,
      inProgress: null,
    },
  },
  isLoading: false,
  error: "",
  isSubmittedSuccess: false,
};

export const submitSuccess = state => ({
  ...state,
  isLoading: false,
  isSubmittedSuccess: true,
});

export const clearSubmitDetail = state => ({
  ...state,
  error: "",
  isSubmittedSuccess: false,
});

export const getUserDetailsSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const clear = () => ({
  ...initialState,
});

export default initialState;
